<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card>
            <async-world-map
              class="map map-big"
              :map-data="mapData"
              :points="points"
            >
            </async-world-map>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import AsyncWorldMap from "@/components/WorldMap/WorldMap.vue";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "vector",
  components: {
    AsyncWorldMap,
    HeaderTopDashboard,
  },
  data() {
    return {
      mapData: {
        AUS: 760,
        BRA: 900,
        CAN: 120,
        DEU: 1300,
        FRA: 540,
        GBR: 690,
        GEO: 200,
        IND: 200,
        ROU: 600,
        RUS: 757,
        USA: 1800,
      },
      points: [
        {
          name: "Brazil",
          centered: "BRA",
        },
        {
          name: "France",
          centered: "FRA",
        },
        {
          name: "Italy",
          centered: "ITA",
        },
        {
          name: "Oman",
          centered: "OMN",
        },
        {
          name: "Indonesia",
          centered: "IDN",
        },
        {
          name: "Romania",
          centered: "ROU",
        },
      ],
    };
  },
};
</script>
